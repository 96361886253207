import React from "react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NetworkConstant } from "../../network/NetworkConstant";
import { RequestType } from "../../network/RequestType";
import {
  makeNetworkCallForDeleteCourseById,
  makeNetworkCallGetAllCourse,
} from "../../redux/action/action";
import { ActionType } from "../../redux/action/actionType";
import { ShowSnackBar } from "../../snackbar/SnackBar";
import { BlueLoader } from "../../util/AppUtils";
import "./CourseList.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Modal from "react-modal";
import deleteLottieJson from "../../assets/lottie/delete_animation.json";
import Lottie from "react-lottie-player";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Slide from "@mui/material/Slide";
import PreviewIcon from "@mui/icons-material/Preview";
import { PublishCoursePopup } from "./course-list-popup/publish-course-popup/PublishCoursePopup";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ReplyIcon from "@mui/icons-material/Reply";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { PublishUnPublishSuccessfulPopup } from "./course-list-popup/publish-unpublish-successful-popup/PublishUnpublishSuccessfulPopup";
import { CopyCoursePopup } from "./course-list-popup/copy-course-popup/CopyCoursePopup";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

const CourseList = () => {
  const apiState = useSelector((state) => state.apiStateReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.courseReducer);
  const [showDeletePopup, setShowDeletePopUp] = useState();
  const [deleteCourseId, setDeleteCourseId] = useState("");
  const [publishCoursePopupProps, setPublishCoursePopupProps] = useState({
    show: false,
    courseName: "",
    courseId: "",
  });
  const [copyCoursePopupProps, setCopyCoursePopupProps] = useState({
    show: false,
    courseName: "",
    destinationCourseId: "",
  });
  const [
    publishUnPublishSuccessfulPopupProps,
    setPublishUnPublishSuccessfulPopupProps,
  ] = useState({
    show: false,
    message: "",
    isPublish: false,
  });

  setTimeout(() => {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: { showSnackBar: false, snackBarMessage: "" },
    });
  }, 5000);

  useEffect(() => {
    dispatch(
      makeNetworkCallGetAllCourse(
        selector.pageCount,
        RequestType.GET,
        NetworkConstant.getAllCourse,
        {},
        {}
      )
    );
    // dispatch({
    //   type: ActionType.UPDATE_PAGE_COUNT,
    //   payload: { pageCount: selector.pageCount + 1 },
    // });
    return () => {
      dispatch({
        type: ActionType.RESET_ALL_COURSE,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    if (selector.courseDeleteResponse.statusCode === 200) {
      setShowDeletePopUp(false);
      dispatch({
        type: ActionType.RESET_ALL_COURSE,
        payload: {},
      });
      dispatch(
        makeNetworkCallGetAllCourse(
          selector.pageCount,
          RequestType.GET,
          NetworkConstant.getAllCourse,
          {},
          {}
        )
      );
    }
  }, [selector.courseDeleteResponse.statusCode]);

  const handleOnDoneClickOfPublishUnPublish = () => {
    setPublishUnPublishSuccessfulPopupProps({ show: false });
    resetGetAllCourseAndFetchAgain();
  };

  function resetGetAllCourseAndFetchAgain() {
    dispatch({
      type: ActionType.RESET_ALL_COURSE,
      payload: {},
    });
    dispatch(
      makeNetworkCallGetAllCourse(
        selector.pageCount,
        RequestType.GET,
        NetworkConstant.getAllCourse,
        {},
        {}
      )
    );
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleCreateCourse = () => {
    navigate("/dashboard/course/create");
  };

  const handleManageCourseClick = (courseResponse) => {
    navigate(`/dashboard/course/${courseResponse.courseId}`, {
      state: courseResponse.courseId,
    });
  };

  const getMessage = (isPublish) => {
    if (isPublish) {
      return "Course published !!";
    } else {
      return "Course Unpublished !!";
    }
  };

  //? handle publish course
  const handlePublishCourse = (courseResponse) => {
    setPublishCoursePopupProps({
      show: true,
      courseName: courseResponse.title,
      courseId: courseResponse.courseId,
      onCancelClick: () => setPublishCoursePopupProps({ show: false }),
      onResponse: (response) => {
        setPublishCoursePopupProps({ show: false });
        setPublishUnPublishSuccessfulPopupProps({
          show: true,
          message: getMessage(response),
          isPublished: response,
          onDoneClick: () => handleOnDoneClickOfPublishUnPublish(),
        });
      },
    });
  };

  //? handle copy course click
  const handleCopyCourseClick = (courseResponse) => {
    setCopyCoursePopupProps({
      show: true,
      courseName: courseResponse.title,
      destinationCourseId: courseResponse.courseId,
      onCancelCopyCoursePopup: () => setCopyCoursePopupProps({ show: false }),
      onResponseCopyCoursePopup: (response) => {
        setCopyCoursePopupProps({
          show: false,
        });
      },
    });
  };

  //? handle share course click
  const handleShareCourseClick = () => {};

  const handleDeleteCourse = (courseResponse) => {
    setDeleteCourseId(courseResponse.courseId);
    setShowDeletePopUp(true);
  };

  const onClickOfYes = () => {
    dispatch(
      makeNetworkCallForDeleteCourseById(
        deleteCourseId,
        RequestType.DELETE,
        NetworkConstant.deleteCourseByCourseId,
        {},
        {}
      )
    );
  };

  const customStyles = {
    content: {
      width: "100%",
      height: "40%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      borderRadius: "10px",
      border: "none",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      display: "flex",
      flexDirection: "column",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  };

  //? actions for speed dial
  const actions = [
    { icon: <FileCopyIcon />, name: "Copy", color: "white" },
    { icon: <LiveTvIcon />, name: "Publish", color: "blue" },
    { icon: <EditIcon />, name: "Manage", color: "blue" },
    { icon: <DeleteIcon />, name: "Delete", color: "red" },
  ];

  return (
    <>
      <div className="infinite-scroll-container">
        <div className="create-course-container">
          <button onClick={handleCreateCourse} className="create-course-button">
            Create Course
          </button>
        </div>

        <InfiniteScroll
          style={{
            marginTop: "10vh",
            width: "100%",
          }}
          height="90vh"
          dataLength={selector.courseResponse.length} //This is important field to render the next data
          next={() => {
            dispatch({
              type: ActionType.UPDATE_PAGE_COUNT,
              payload: { pageCount: selector.pageCount + 1 },
            });
            dispatch(
              makeNetworkCallGetAllCourse(
                selector.pageCount,
                RequestType.GET,
                NetworkConstant.getAllCourse,
                {},
                {}
              )
            );
          }}
          hasMore={
            selector.totalNumberOfPagesAsPerGivenPageLimit >
            selector.courseResponse.length
              ? true
              : false
          }
          scrollableTarget="infinite-scroll-container"
        >
          {selector.courseResponse.map((val, key) => {
            return (
              !val.isSortCourse && (
                <div className="course-card" key={key}>
                  {val.isPublished ? (
                    <img
                      src={require("../../assets/image/green_circle.png")}
                      height={20}
                      width={20}
                    />
                  ) : (
                    <img
                      src={require("../../assets/image/red_circle.png")}
                      height={20}
                      width={20}
                    />
                  )}
                  <div
                    style={{
                      padding: "3%",
                    }}
                  >
                    <img
                      src={val.thumbnail}
                      height={120}
                      width={120}
                      style={{
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div className="content-text">
                    <h3
                      style={{
                        fontFamily: "FuturaCondensed",
                        textTransform: "uppercase",
                        fontSize: "x-large",
                      }}
                    >
                      {val.title}
                    </h3>
                    <p
                      style={{
                        fontFamily: "FuturaCondensedLight",
                        fontSize: "large",
                      }}
                    >
                      {val.description}
                    </p>
                    <div className="manage-button-container">
                      <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        icon={<SpeedDialIcon />}
                        FabProps={{
                          size: "small",
                        }}
                        direction="left"
                        style={{
                          marginRight: "20px",
                        }}
                      >
                        {actions.map((action) => (
                          <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            // sx={{
                            //   backgroundColor: action.color,
                            // }}
                            onClick={() => {
                              if (action.name === "Copy") {
                                handleCopyCourseClick(val);
                              } else if (action.name === "Publish") {
                                handlePublishCourse(val);
                              } else if (action.name === "Manage") {
                                handleManageCourseClick(val);
                              } else if (action.name === "Delete") {
                                handleDeleteCourse(val);
                              }
                            }}
                          />
                        ))}
                      </SpeedDial>
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <div>
                            <Tooltip title="Share" arrow>
                              <Fab
                                className="button-focus"
                                style={{
                                  marginRight: "20px",
                                }}
                                aria-label="Share"
                                size="small"
                                onClick={(event) => handleShareCourseClick(val)}
                                {...bindTrigger(popupState)}
                              >
                                <ReplyIcon />
                              </Fab>
                            </Tooltip>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <div className="course-share-container">
                                <FacebookShareButton
                                  url={`https://www.reproneetlearning.com/course/${val.courseId}`}
                                  quote={`${val.title}`}
                                  hashtag="#Reproneet's top courses"
                                >
                                  <FacebookIcon
                                    logoFillColor="white"
                                    round={true}
                                    size={40}
                                  />
                                </FacebookShareButton>
                                <WhatsappShareButton
                                  title={`${val.title}`}
                                  url={`https://www.reproneetlearning.com/course/${val.courseId}`}
                                  style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                  }}
                                >
                                  <WhatsappIcon
                                    logoFillColor="white"
                                    round={true}
                                    size={40}
                                  />
                                </WhatsappShareButton>
                                <TelegramShareButton
                                  title={`${val.title}`}
                                  url={`https://www.reproneetlearning.com/course/${val.courseId}`}
                                >
                                  <TelegramIcon
                                    logoFillColor="white"
                                    round={true}
                                    size={40}
                                  />
                                </TelegramShareButton>
                              </div>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </InfiniteScroll>

        {apiState.showLoader && <BlueLoader />}
        <ShowSnackBar
          severity="error"
          isVisible={apiState.showSnackBar}
          message={apiState.snackBarMessage}
        />
        {showDeletePopup && (
          <Dialog open={showDeletePopup}>
            <DialogTitle>
              <div className="delete-container">
                <Lottie
                  loop
                  animationData={deleteLottieJson}
                  play
                  style={{ width: 150, height: 150 }}
                />
                <p className="delete-message">
                  Are you sure you want to delete ?
                </p>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="button-container-delete">
                <button
                  className="button-style-no"
                  onClick={() => {
                    setDeleteCourseId("");
                    return setShowDeletePopUp(false);
                  }}
                >
                  No
                </button>
                <button className="button-style-yes" onClick={onClickOfYes}>
                  Yes
                </button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <PublishCoursePopup {...publishCoursePopupProps} />
      <PublishUnPublishSuccessfulPopup
        {...publishUnPublishSuccessfulPopupProps}
      />
      <CopyCoursePopup {...copyCoursePopupProps} />
    </>
  );
};

export default CourseList;
